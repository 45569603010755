






































import { defineComponent } from '@vue/composition-api';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import VideoAppComponent from '@/components/video/videoApp.component.vue';

import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'video-slider',
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    VideoAppComponent,
  },
  props: {
    videos: {
      type: Array,
    },
  },
  data() {
    return {
      currentVideo: null,
      playVideo: false,
      hooperSettings: {
        itemsToShow: 1.125,
        autoPlay: window.innerWidth > 767,
        infiniteScroll: true,
        playSpeed: 5000,
        wheelControl: false,
        breakpoints: {
          520: {
            itemsToShow: 1.48,
          },
          768: {
            itemsToShow: 1,
          },
        },
      },
      observer: null as null | IntersectionObserver,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      threshold: 0.1,
      rootMargin: '300px',
    });
    const target = document.querySelector('#videoSlider');
    if (target && window.innerWidth > 767) this.observer.observe(target);
  },
  mounted() {
    if (window.innerWidth > 767) {
      document.querySelectorAll('.hooper-indicators button').forEach((el) => {
        el.addEventListener('click', () => {
          TrackingUtils.trackEvent({
            eventCategory: 'video',
            eventAction: `slide_change_${(this.$refs.carousel as any).currentSlide + 1}`,
            eventLabel: 'videostage',
          });
        });
      });
    }
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    onElementObserved(entries: IntersectionObserverEntry[]) {
      entries.forEach(({ target, isIntersecting }: any) => {
        if (!isIntersecting) {
          return;
        }
        (this.$refs.carousel as any).restartTimer();
        (this.$refs.carousel as any).slideTo(0);
        if (this.observer) this.observer.unobserve(target);
      });
    },
    onSlide(event?: { currentSlide: number; slideFrom: number }) {
      if (!event || event.slideFrom === null) return;
      if (window.innerWidth <= 767) {
        if (event.currentSlide < 0) {
          event.currentSlide = this.videos!.length - 1;
        } else if (event.currentSlide > this.videos!.length - 1) {
          event.currentSlide = 0;
        }
        const index = (event.currentSlide + 1).toString();
        TrackingUtils.trackEvent({
          eventCategory: 'video',
          eventAction: `slide_change_${index}`,
          eventLabel: 'videostage',
        });
      }
    },
    startVideo(video: any) {
      this.currentVideo = video;
      this.playVideo = true;
    },
    closeVideo() {
      this.currentVideo = null;
      this.playVideo = false;
    },
  },
});
